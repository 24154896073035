import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./digitalSecurity.module.css";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";
import { GoDash } from "react-icons/go";
import StripBanner from "../../components/StripBanner";
import CommonCarousel from "../../components/CommonCarousel";

const DigitalSecurity = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Comprehensive Risk Assessment & Analysis",
      desc: `The first step in developing a strong cybersecurity strategy is understanding where your vulnerabilities lie. Our team conducts a thorough <span class="fw-bold" >risk assessment</span> to identify potential weaknesses in your systems, networks, and software. We analyze your organization’s current security posture, pinpoint risks, and provide actionable insights to mitigate these vulnerabilities.`,
    },
    {
      id: "2",
      title: "Data Protection and Compliance",
      desc: `Data is the most valuable asset for any business, making <span class="fw-bold">data protection</span> one of the key pillars of our cybersecurity strategy. Our experts work with you to establish robust data security protocols, ensuring compliance with regulations such as GDPR and CCPA. We use encryption, secure access controls, and data masking to protect your sensitive information from unauthorized access and cyberattacks.`,
    },
    {
      id: "3",
      title: "Cyber Threat Intelligence Services",
      desc: `With cyber threats constantly evolving, businesses must stay updated on the latest risks. Our <span class="fw-bold" >cyber threat intelligence services</span> provide real-time monitoring and alerts for new and emerging threats. By leveraging advanced threat detection tools and intelligence networks, we help you detect and neutralize potential attacks before they cause harm to your business.`,
    },
    {
      id: "4",
      title: "Security Policy Development",
      desc: `Developing a robust security policy is vital for ensuring that your entire organization follows best practices in digital security. Our consultants work closely with you to develop comprehensive <span class="fw-bold">cybersecurity policies</span> that cover everything from access control to disaster recovery. This serves as a framework for your team to follow, ensuring that everyone is on the same page regarding data and network security.`,
    },
    {
      id: "5",
      title: "Security Architecture Design and Implementation",
      desc: `The backbone of any secure enterprise is its underlying architecture. Our team of experts designs resilient and scalable <span class="fw-bold">security architecture</span> that protects your network, cloud infrastructure, and applications from evolving cyber threats. Once designed, we implement advanced security measures, including firewalls, intrusion detection systems (IDS), and secure access management tools, to safeguard your enterprise assets.`,
    },
  ];

  const carouselItems = [
    {
      src: "/assets/future.WebP",
      alt: "Identity and Access Management (IAM)",
      label: "Identity and Access Management (IAM)",
      text: "Managing user access is crucial in preventing unauthorized data breaches and ensuring that only the right individuals have access to sensitive information. Our Identity and Access Management (IAM) solutions include multi-factor authentication (MFA), role-based access control, and privileged access management to secure your enterprise’s resources. We monitor and control who can access specific areas of your network, reducing the risk of insider threats and data leaks.",
    },
    {
      src: "/assets/Robotic-ai.WebP",
      alt: "Incident Response and Cybersecurity Resilience",
      label: "Incident Response and Cybersecurity Resilience",
      text: "No matter how robust your defences are, cyberattacks can still occur. That’s why it's essential to have a well-planned incident response strategy. Our team helps you prepare for and respond to any security incidents, ensuring that breaches are quickly contained and resolved. We provide ongoing cybersecurity resilience training to your team, so they are equipped to handle potential threats and mitigate damage effectively.",
    },
    {
      src: "/assets/data-engineering-service.WebP",
      alt: "Data Backup and Disaster Recovery",
      label: "Data Backup and Disaster Recovery",
      text: "Data loss can have devastating consequences on your business, leading to downtime, loss of customer trust, and financial losses. We implement reliable data backup and disaster recovery strategies to ensure that your critical information is always recoverable, even in the event of a cyberattack. By creating secure backups and conducting regular tests, we help you minimize data loss and restore normal operations with minimal disruption.",
    },
  ];

  const customCard = {
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "8px",
    padding: "3px",
  };

  return (
    <>
      <Helmet>
        <title>
          Comprehensive Cybersecurity Services for Business Protection
        </title>
        <meta
          name="keywords"
          content="IT threat prevention and digital security services company"
        />
        <meta
          name="description"
          content="Protect your business with expert cybersecurity services. Ensure data protection, threat intelligence, and security compliance for a secure digital landscape."
        />
        <meta
          property="og:title"
          content="Trusted Cyber Security Company for Complete Data Protection"
        />
        <meta
          property="og:description"
          content="Trust our cyber security company to protect your data with advanced threat intelligence, robust security solutions, and 24/7 monitoring for peace of mind."
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/digitalsecurity-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>
            Cybersecurity Services: Safeguard Your Business from Emerging
            Threats
          </h1>
          <h6>
            Empowering enterprises with robust cybersecurity solutions to
            protect data, mitigate risks, and prevent cyber threats.
          </h6>
        </div>
      </CommonBannerImage>

      <div className="mt-4">
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h3 className="mb-1 fs-3 text-black">
                Digital Security Services:
              </h3>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash
                  style={{
                    color: "#007fc7",
                    position: "relative",
                    top: "-.59em",
                  }}
                />{" "}
                Protecting Your Enterprise with Advanced Cybersecurity Solutions
              </h5>
              <p>
                In today’s interconnected digital landscape, cybersecurity is no
                longer optional it’s essential. Businesses of all sizes face
                growing cyber threats that can compromise their sensitive data,
                financial stability, and reputation. At Orbio Solutions, our
                comprehensive{" "}
                <span className="fw-bold">cybersecurity services</span> are
                designed to keep your digital assets safe from evolving cyber
                risks. As a trusted{" "}
                <span className="fw-bold">cyber security company</span>, we help
                organizations stay one step ahead of potential threats with our
                proactive solutions, expert insights, and advanced technologies.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="digital-security"
                src="/assets/digital-security-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "100%", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <StripBanner
            title="Why Cybersecurity Services Are Critical for Your Business"
            desc={`
              <p>
                Cybersecurity goes beyond just installing firewalls and antivirus software. It’s a strategic approach that involves assessing, preventing, detecting, and responding to threats at every level of your organization. With the rise of ransomware attacks, data breaches, and sophisticated cybercriminals, businesses need to fortify their defenses and implement long-term security measures.
              </p>
              <p>
                At Orbio Solutions, we understand that every business is unique, which is why we offer tailored cybersecurity services. Our solutions encompass everything from data protection and risk assessment to cyber threat intelligence services and incident response, ensuring complete security for your enterprise.
            `}
          />
        </Container>
      </div>

      <ServiceKeyPoints
        heading="Our Cybersecurity Approach – Comprehensive and Proactive:"
        keyPoints={keyPoints}
        customHeight={"250px"}
      />

      <div className="mt-4">
        <Container>
          <h3 className="text-center mb-4 text-black">
            Advanced Cybersecurity Services for Business Continuity
          </h3>
          <CommonCarousel carouselItems={carouselItems} />
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-black">
            Orbio Solutions – Your Trusted Cyber Security Company{" "}
          </h3>
          <p>
            At Orbio Solutions, we pride ourselves on delivering expert{" "}
            <span className="fw-bold">cybersecurity services</span> that
            safeguard your enterprise and ensure business continuity. With our
            deep expertise in{" "}
            <span className="fw-bold">cyber threat intelligence services</span>,
            data protection, and risk mitigation, we help you stay ahead of the
            curve in an ever-changing threat landscape.
          </p>
          <p>
            Our solutions are designed to adapt to your business needs, and we
            work closely with you to craft a custom cybersecurity strategy that
            not only protects your data but also enhances your operational
            efficiency. Our team of dedicated cybersecurity professionals is
            always on standby to assist with any security-related challenges.
          </p>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-balck">
            Why Choose Orbio Solutions for Cybersecurity Services?
          </h3>
          <Row>
            <Col md={6} className="mb-2 mb-md-0">
              <Card style={customCard}>
                <Card.Body>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} /> Proactive Protection
                  </Card.Title>
                  <Card.Text className="textJustify" style={{ color: "black" }}>
                    Our cybersecurity services focus on preventing cyberattacks
                    before they occur, using advanced threat detection and
                    prevention tools.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={customCard}>
                <Card.Body style={{ color: "black" }}>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    Expert Insights
                  </Card.Title>
                  <Card.Text className="textJustify">
                    With a team of experienced cybersecurity professionals, we
                    provide insights that help you make informed security
                    decisions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-md-2 mb-4">
            <Col md={6} className="mb-2 mb-md-0 mt-2 mt-md-0">
              <Card style={customCard}>
                <Card.Body>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    Custom Solutions
                  </Card.Title>
                  <Card.Text className="textJustify" style={{ color: "black" }}>
                    We tailor our services to meet the specific needs of your
                    business, ensuring that you receive the right level of
                    protection.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={customCard}>
                <Card.Body style={{ color: "black" }}>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    Ongoing Support
                  </Card.Title>
                  <Card.Text className="textJustify">
                    Our team provides continuous monitoring, support, and
                    improvements to keep your business secure in the face of
                    evolving threats.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ContactFormService
        caseStudyLength={1}
        title={
          "We are here to help secure your business. To learn more about our comprehensive cybersecurity solutions, please contact us using the form below. Let us know how we can assist in protecting your digital assets and enterprise systems."
        }
      />
    </>
  );
};

export default DigitalSecurity;
