import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./technologyConsulting.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";
import StripBanner from "../../components/StripBanner";
import ContactFormService from "../../components/ContactFormService";
import { GoDash } from "react-icons/go";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";
import CommonCarousel from "../../components/CommonCarousel";
import { Link } from "react-router-dom";

const TechnologyConsulting = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Needs Assessment",
      desc: "Our consulting process begins with a thorough needs assessment to understand your business objectives and technology requirements. We analyze your current IT environment to identify areas for improvement and opportunities for innovation.",
    },
    {
      id: "2",
      title: "Requirement Gathering",
      desc: "Accurate requirement gathering is crucial for effective technology solutions. We collect detailed information about your business processes and goals to ensure that our solutions are aligned with your strategic objectives.",
    },
    {
      id: "3",
      title: "Strategy Roadmap Creation",
      desc: "A well-defined strategy roadmap is essential for successful technology implementation. We develop a comprehensive plan that outlines the steps needed to achieve your technology goals, including timelines, resource allocation, and milestones.",
    },
    {
      id: "4",
      title: "Solution Design and Recommendation",
      desc: `Our <span class="fw-bold">technology consultants</span> craft tailored technology solutions designed to address your specific business challenges. We provide recommendations based on best practices and industry standards to ensure that your technology investments deliver maximum value.`,
    },
    {
      id: "5",
      title: "Assist with Implementation",
      desc: "Effective implementation is key to realizing the benefits of technology solutions. We offer hands-on assistance throughout the implementation process to ensure a smooth transition and successful deployment of your technology solutions.",
    },
    {
      id: "6",
      title: "Technical Support",
      desc: "Ongoing support is crucial for maintaining the performance of your technology solutions. Our robust technical support services are available to resolve issues promptly and ensure the continued effectiveness of your technology investments.",
    },
    {
      id: "7",
      title: "Stakeholder Communication",
      desc: `Clear and transparent communication with <a href="https://en.wikipedia.org/wiki/Stakeholder" target="_blank" rel="noreferrer" >stakeholders</a> is essential for successful technology consulting. We keep all relevant parties informed throughout the consulting process, ensuring that expectations are managed and objectives are met.`,
    },
  ];

  const carouselItems = [
    {
      src: "/assets/laptopSecurity.WebP",
      alt: "Aligning Technology",
      label: "Aligning Technology with Business Strategy",
      text: "At Orbio Solutions, our approach is centered on aligning technology with your overall business strategy. We work closely with you to understand your strategic goals and design technology solutions that support your vision for growth and success.",
    },
    {
      src: "/assets/industry.WebP",
      alt: "Leveraging Industry Expertise",
      label: "Leveraging Industry Expertise",
      text: "As one of the top tech consulting firms, we leverage our extensive industry expertise and a wide network of technology partners to deliver innovative and effective solutions. Our team stays up-to-date with the latest trends and advancements to provide you with cutting-edge technology consulting services.",
    },
    {
      src: "/assets/commitment.WebP",
      alt: "Fostering Long-Term Partnerships",
      label: "Fostering Long-Term Partnerships",
      text: "We view our client relationships as long-term partnerships. Our goal is not only to deliver immediate results but also to support your ongoing technology needs and contribute to your long-term success. We provide continuous support and guidance to help you adapt to changing technology landscapes.",
    },
    {
      src: "/assets/future.WebP",
      alt: "Emphasizing Customization and Flexibility",
      label: "Emphasizing Customization and Flexibility",
      text: "Every business is unique, and so are its technology needs. Our technology consulting services are highly customizable, allowing us to tailor our solutions to fit your specific requirements and business objectives. We offer flexible engagement models to accommodate your preferences and needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Top IT Technology Consulting Company for Tailored Solutions
        </title>
        <meta
          name="description"
          content="Orbio Solutions:Your premier IT technology consulting company. Expert guidance, tailored strategies, and long-term support to drive your digital transformation"
        />
        <meta
          property="og:title"
          content="Top Technology Consulting Services for Strategic Innovation"
        />
        <meta
          property="og:description"
          content="Explore premier technology consulting services with Orbio Solutions.Tailored strategies, expert insights, and long-term support for your business success."
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/technologyconsulting-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Leading IT Technology Consulting Company: Innovate with Us</h1>
          <h6>
            Transform your business with expert IT technology consulting
            services. Partner with the top tech consulting firm for customized
            solutions and strategic guidance.
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-4">
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h3 className="mb-1 fs-3 text-black">
                IT Technology Consulting Company:
              </h3>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash style={{ color: "#007fc7" }} />
                Driving Innovation and Digital Transformation
              </h5>
              <p>
                In a rapidly evolving digital landscape, businesses must
                navigate complex technology challenges to stay competitive. As a
                premier{" "}
                <Link to="/about-us">IT technology consulting company</Link>,
                Orbio Solutions provides strategic guidance and innovative
                solutions to help organizations achieve their technology goals.
                Our comprehensive
                <span className="fw-bold">
                  {" "}
                  technology consulting services
                </span>{" "}
                are designed to optimize your technology investments and drive
                business growth.
              </p>
              <p>
                Our team of expert{" "}
                <span className="fw-bold">technology consultants</span>{" "}
                leverages extensive industry experience and a broad partner
                network to deliver customized solutions tailored to your unique
                business needs. We focus on aligning technology with your
                strategic objectives to accelerate your digital transformation
                journey and ensure long-term success.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="technology"
                src="/assets/technology-screen2.WebP"
                fluid
                className={styles.screenImg}
              />
            </Col>

            <StripBanner
              title="Why Orbio Solutions Stands Out in Technology Consulting"
              desc={`
                At Orbio Solutions, we pride ourselves on our ability to offer
                top-notch consulting services that address the most pressing
                technology challenges faced by businesses today. Our approach is
                built on understanding your needs, crafting bespoke solutions,
                and providing ongoing support to ensure successful technology
                adoption. Here’s how we stand out as one of the leading <a href="/" target="_blank"
                rel="noreferrer">tech
                consulting firms</a>:
              </p>
              `}
            />
          </Row>
        </Container>
      </div>
      <ServiceKeyPoints
        heading="Comprehensive Technology Consulting Services:"
        keyPoints={keyPoints}
      />

      <ServiceMainContent />

      <div className="mt-4">
        <Container>
          <h3 className="text-center mb-4 text-black">
            Our Approach to IT Technology Consulting
          </h3>
          <CommonCarousel carouselItems={carouselItems} />
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-black">
            Partner with Orbio Solutions for Expert Technology Consulting
          </h3>
          <p>
            Choosing Orbio Solutions means partnering with a leading{" "}
            <span className="fw-bold">IT technology consulting company</span>{" "}
            dedicated to driving innovation and optimizing technology
            investments. Our expertise in{" "}
            <span className="fw-bold">technology consulting services</span> and
            commitment to client success set us apart as a trusted advisor in
            the technology space.
          </p>
          <p>
            Whether you're looking to enhance your technology strategy,
            implement new solutions, or resolve existing challenges, our team of
            expert <span className="fw-bold">technology consultants</span> is
            here to help. We provide a full range of{" "}
            <span className="fw-bold">technology consulting services</span>{" "}
            designed to support your business's technology needs and drive
            meaningful outcomes.
          </p>
        </Container>
      </div>
      <ContactFormService
        caseStudyLength={1}
        title="Ready to transform your technology strategy and drive business success? Contact Orbio Solutions today to learn more about our technology consulting services and how we can help you achieve your technology goals."
      />
    </>
  );
};

export default TechnologyConsulting;
