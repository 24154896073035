import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./qualityEngineering.module.css";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";
import { GoDash } from "react-icons/go";
import StripBanner from "../../components/StripBanner";
import CommonCarousel from "../../components/CommonCarousel";
import CommonCards from "../../components/CommonCards";

const QualityEngineering = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Quality Planning",
      desc: `Effective quality planning is the foundation of every project we undertake. Our team works closely with you to understand your project requirements, define quality objectives, and develop a comprehensive strategy to ensure optimal <span class="fw-bold" >software development quality assurance</span>. This allows us to build a strong testing framework tailored to your business needs.`,
    },
    {
      id: "2",
      title: "Integration with Design and Development",
      desc: `At Orbio, we integrate <span class="fw-bold" >software quality engineering</span> principles from the early stages of the design and development process. By embedding testing methodologies into the core development cycle, we identify potential risks early on, ensuring faster time to market and a reduction in costly rework.`,
    },
    {
      id: "3",
      title: "Setting Up Comprehensive Test Environments",
      desc: `We create robust, scalable test environments tailored to the unique needs of your application. By simulating real-world scenarios, we guarantee that your software performs efficiently under diverse conditions, helping you avoid post-launch surprises.`,
    },
    {
      id: "4",
      title: "Continuous Integration and Testing",
      desc: `To accelerate the development process and maintain high standards of quality, we implement continuous integration (CI) tools and methodologies. CI allows us to run frequent, automated tests throughout the development cycle, ensuring that defects are identified and resolved swiftly.`,
    },
    {
      id: "5",
      title: "Defect Management and Resolution",
      desc: `Managing defects is crucial to maintaining superior product quality. Our systematic approach to defect management ensures that all issues are logged, tracked, and resolved efficiently. Our engineers prioritize defects based on severity, ensuring that the most critical issues are addressed before product release.`,
    },
  ];

  const carouselItems = [
    {
      src: "/assets/future.WebP",
      alt: "Functional Testing",
      label: "Functional Testing",
      text: "Our software testing service provider includes thorough functional testing to verify that your application performs according to specifications. We simulate various user scenarios and perform regression tests to guarantee seamless functionality across different platforms and devices.",
    },
    {
      src: "/assets/performance.WebP",
      alt: "Performance and Load Testing",
      label: "Performance and Load Testing",
      text: "We conduct performance and load testing to ensure that your software can handle high levels of traffic, multiple users, and peak loads. Our goal is to identify potential bottlenecks and optimize application performance for enhanced user experience.",
    },
    {
      src: "/assets/secirity.WebP",
      alt: "Security Testing",
      label: "Security Testing",
      text: "Protect your application from potential threats with our robust security testing services. We identify vulnerabilities, ensure data protection, and validate your application’s compliance with the latest cybersecurity standards. Security is a critical part of our software development quality assurance process, safeguarding your business and user data.",
    },
    {
      src: "/assets/laptopSecurity.WebP",
      alt: "Automation Testing",
      label: "Automation Testing",
      text: "Speed up your development process with our automation testing services. We build custom test automation frameworks that allow for continuous, rapid testing of your application’s features, enhancing efficiency and reducing time to market.",
    },
  ];

  const cardData = [
    {
      id: "1",
      title: "Performance and Security Testing",
      img: "/assets/businessitsolution.WebP",
      desc: "Our performance and security testing services ensure that your software withstands high traffic volumes and is resilient to potential security threats. We leverage cyber threat intelligence services to identify risks, ensuring your product is secure and optimized.",
    },
    {
      id: "2",
      title: "Final Validation and Release",
      img: "/assets/datadriven.WebP",
      desc: "Before your software goes live, we conduct a thorough final validation process to verify that all functions are working correctly. Once validated, your product is ready for release, and fully optimized for performance, security, and usability",
    },
    {
      id: "3",
      title: "Documentation and Reporting",
      img: "/assets/technologyconsulting-bg.WebP",
      desc: "Transparency is at the heart of what we do. Throughout the testing process, we maintain comprehensive documentation and generate detailed reports to give you clear insights into your software’s quality and performance.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Leading Software Testing Company | Expert QA Services</title>
        <meta
          name="keywords"
          content="Quality engineering implementation service provider"
        />
        <meta
          name="description"
          content="Partner with a top software testing company for reliable QA and thorough testing services. Ensure peak performance and quality with our expert solutions."
        />
        <meta
          property="og:title"
          content="Top Software Testing Service Provider | Expert QA Services"
        />
        <meta
          property="og:description"
          content="Choose our software testing service provider for expert QA solutions. Ensure your software's reliability and performance with our comprehensive testing services."
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/quality-engineering-bg1.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>
            Leading Software Testing Company for Secure & Reliable Solutions
          </h1>
          <h6>
            Partner with Orbio Solutions, a trusted software testing company, to
            ensure high-performance, secure, and reliable software through
            quality testing services.
          </h6>
        </div>
      </CommonBannerImage>

      <div className="mt-4">
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h3 className="mb-1 fs-3 text-black">Orbio Solutions:</h3>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash style={{ color: "#007fc7" }} /> Your Reliable Software
                Testing Company
              </h5>
              <p>
                At Orbio Solutions, we believe that quality is the cornerstone
                of any successful software product. Our comprehensive range of{" "}
                <span className="fw-bold">software testing services</span>{" "}
                ensures that your applications deliver superior performance,
                reliability, and security. With years of experience as a trusted{" "}
                <span className="fw-bold">software testing company</span>, we
                are committed to helping businesses optimize their software
                development lifecycle and exceed customer expectations. From web
                apps and mobile apps to enterprise solutions, we apply our
                expertise across multiple environments, ensuring that every
                product we test meets the highest standards of quality.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="quality-engineering"
                src="/assets/quality-engineering-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "100%", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <StripBanner
            title="What Makes Orbio Solutions the Ideal Choice for Software Testing?"
            desc={`
              <p>
                At Orbio, our <spn class="fw-bold" >software testing services</span> are designed to provide businesses with an all-encompassing approach to quality assurance. Whether you need manual testing, automation testing, performance testing, or security testing, our team of experienced engineers delivers customized solutions that align with your business needs.
              </p>
              <p>
                We go beyond traditional software testing to offer a blend of innovative strategies, rigorous testing methodologies, and cutting-edge technologies to improve product quality. Our goal is to reduce risks, identify potential vulnerabilities, and help you launch secure, high-performing software.
              </p>
            `}
          />
        </Container>
      </div>

      <ServiceKeyPoints
        heading="How We Approach Quality Engineering:"
        keyPoints={keyPoints}
        customHeight={"220px"}
      />

      <div className="mt-4">
        <Container>
          <h3 className="text-center mb-4 text-black">
            Comprehensive Software Testing Services
          </h3>
          <CommonCarousel carouselItems={carouselItems} />
        </Container>
      </div>

      <div className="mt-4">
        <CommonCards
          cardData={cardData}
          title={"How We Ensure Software Quality"}
          col={4}
        />
      </div>

      <div className="mt-4">
        <Container>
          <StripBanner
            title="The Orbio Difference: Quality Beyond Testing"
            desc={`
              <p>
            At Orbio, we don’t just test software we build a comprehensive
            quality framework that ensures ongoing improvements throughout the
            product lifecycle. Our blend of advanced testing methodologies,
            automation tools, and personalized consulting helps us identify
            opportunities for continuous improvement, even after your software
            is released.
          </p>
          <p>
            Our commitment to quality has earned us the trust of clients across
            multiple industries. We stand out as a
            <span class="fw-bold">cyber security company</span>, providing
            <span class="fw-bold">software quality engineering</span>
            solutions that are innovative, secure, and tailored to your unique
            business requirements.
          </p>
            `}
          />
        </Container>
      </div>

      <ContactFormService
        caseStudyLength={1}
        title="Get in touch today to learn how Orbio Solutions can help you achieve flawless performance with our comprehensive software testing services."
      />
    </>
  );
};

export default QualityEngineering;
