import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./cloudManagedServices.module.css";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import { GoDash } from "react-icons/go";
import StripBanner from "../../components/StripBanner";
import CommonCards from "../../components/CommonCards";
import CommonCarousel from "../../components/CommonCarousel";
import { FaLightbulb, FaShieldAlt } from "react-icons/fa";
import { MdDeveloperMode } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { AiOutlineDollar } from "react-icons/ai";
import { GiMove } from "react-icons/gi";
import { Link } from "react-router-dom";

const CloudManagedServices = () => {
  const carouselItems = [
    {
      src: "/assets/businessitsolution.WebP",
      alt: "Evaluate Current Infrastructure",
      label: "Evaluate Current Infrastructure",
      text: "Before embarking on your cloud journey, we assess your current IT infrastructure to identify strengths, weaknesses, and areas for improvement. This assessment ensures that your transition to the cloud is seamless and aligned with your business objectives.",
    },
    {
      src: "/assets/future.WebP",
      alt: "Cloud Strategy Development",
      label: "Cloud Strategy Development",
      text: "A one-size-fits-all approach doesn’t work in cloud computing. Our team of expert cloud consultants works closely with you to design a tailored cloud strategy that aligns with your business goals. Whether you need to scale up, enhance security, or optimize costs, we create a roadmap to success.",
    },
    {
      src: "/assets/data-engineering-service.WebP",
      alt: "Cloud Selection and Design",
      label: "Cloud Selection and Design",
      text: "Choosing the right cloud environment is essential for maximizing performance and scalability. Whether you're opting for public, private, or hybrid cloud solutions, we guide you in selecting and designing the optimal cloud architecture for your business. Our deep partnerships with leading cloud providers enable us to deliver cutting-edge cloud services and solutions.",
    },
    {
      src: "/assets/Robotic-ai.WebP",
      alt: "Migration Planning and Execution",
      label: "Migration Planning and Execution",
      text: `Migrating to the cloud involves meticulous planning and precise execution. Our experts ensure that your data, applications, and services are smoothly migrated to the cloud with minimal disruption. Our robust cloud security services ensure <a href="https://en.wikipedia.org/wiki/Data_integrity" target="_blank" rel="noreferrer" >data integrity</a> and protection during the entire migration process.`,
    },
    {
      src: "/assets/digitalsecurity-bg.WebP",
      alt: "Cloud Security Services",
      label: "Cloud Security Services",
      text: "Security is paramount when it comes to cloud computing. Our advanced cloud security services protect your business against cyber threats, ensuring data privacy and regulatory compliance. From encryption to multi-factor authentication, we offer comprehensive cloud security solutions that give you peace of mind.",
    },
  ];

  const cardData = [
    {
      id: "1",
      title: "Implementation of Cloud Management Tools",
      img: "/assets/cloud-tools.WebP",
      desc: "Once the migration is complete, we implement state-of-the-art cloud management tools that enhance operational efficiency. These tools allow for real-time monitoring, automated workflows, and quick issue resolution, enabling your business to perform at its best in a cloud-driven environment.",
    },
    {
      id: "2",
      title: "Performance Monitoring and Optimization",
      img: "/assets/datadriven.WebP",
      desc: "We continuously monitor your cloud environment to ensure optimal performance. Our team identifies bottlenecks and implements necessary adjustments to keep your cloud infrastructure running smoothly. With a focus on scalability and agility, we help your business adapt to changing demands.",
    },
    {
      id: "3",
      title: "Cost Management and Optimization",
      img: "/assets/driving-sales-profits-bg.WebP",
      desc: "Cloud services offer numerous cost benefits, but without proper management, they can lead to overspending. We help you manage and optimize your cloud costs through proactive resource allocation and budget forecasting, ensuring that you get the most value from your investment.",
    },
  ];

  const customCard = {
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "8px",
    padding: "3px",
  };

  return (
    <>
      <Helmet>
        <title>Cloud Managed Services | Secure, Scalable Cloud Solutions</title>
        <meta
          name="keywords"
          content="Managed cloud service provider and consulting company"
        />
        <meta
          name="description"
          content="Optimize your cloud strategy with Orbio's expert cloud managed services, offering cloud migration, security, and cost-efficient solutions for seamless operations."
        />
        <meta
          property="og:title"
          content="Comprehensive Cloud Services and Solutions | Orbio Solutions"
        />
        <meta
          property="og:description"
          content="Discover our expert cloud managed services for seamless migration, optimization, and security. Leading cloud services and solutions for modern businesses."
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/cloudmanaged.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>
            Cloud Managed Services: Transform Your Business with Expert Cloud
            Solutions
          </h1>
          <h6>
            Harness the power of the cloud with our comprehensive cloud managed
            services. From migration to optimization, we secure, manage, and
            elevate your cloud strategy.
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-4">
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h3 className="mb-1 fs-3 text-black">Cloud Managed Services:</h3>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash style={{ color: "#007fc7" }} /> Secure, Scalable, and
                Tailored for Your Business
              </h5>
              <p>
                In today's competitive landscape, leveraging cloud technology is
                no longer just an option but a critical business strategy. Our
                cloud managed services enable businesses to transition smoothly
                into the cloud while maintaining security, scalability, and cost
                efficiency. Whether you're an SME or a global enterprise, we
                offer end-to-end cloud solutions that not only boost
                collaboration and flexibility but also drive your business
                towards sustainable growth.
              </p>
              <p>
                As a leading <span className="fw-bold">cloud consultant</span>,
                we specialize in offering customized{" "}
                <Link to="/business-it-solutions">
                  cloud services and solutions
                </Link>{" "}
                tailored to meet your business needs. Our expert engineers
                ensure your business operates at peak efficiency with a
                well-managed cloud infrastructure, and our{" "}
                <span className="fw-bold">cloud security services</span> keep
                your data safe in this ever-evolving digital landscape.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="cloud-managed"
                src="/assets/cloud-managed-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-4">
        <Container>
          <StripBanner
            title="Why Choose Orbio for Cloud Managed Services?"
            desc={`<p>
          At Orbio Solutions, we combine expertise with innovation to deliver world-class cloud managed services. From cloud strategy development to ongoing cloud security, we have the tools, knowledge, and partnerships to elevate your cloud infrastructure. Here’s how we can help your business leverage the cloud effectively.
          </p>
          `}
          />
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-center mb-4 text-black">
            Comprehensive Cloud Managed Services
          </h3>
          <CommonCarousel carouselItems={carouselItems} />
        </Container>
      </div>

      <div className="mt-4">
        <CommonCards
          cardData={cardData}
          title={"Optimizing Your Cloud Environment for Success "}
          col={4}
        />
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-black">
            Empowering Your Team with Cloud Expertise{" "}
          </h3>
          <Row>
            <Col md={6} className="mb-2 mb-md-0">
              <Card style={customCard}>
                <Card.Body>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} /> User Training and
                    Change Management
                  </Card.Title>
                  <Card.Text className="textJustify" style={{ color: "black" }}>
                    A successful cloud strategy involves more than just
                    technology it requires people who are well-equipped to
                    leverage it. We provide comprehensive user training to
                    ensure your team is comfortable with cloud tools and
                    processes. Our change management strategies ease the
                    transition, allowing your team to adapt quickly and
                    efficiently.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={customCard}>
                <Card.Body style={{ color: "black" }}>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} /> Continuous
                    Improvement and Adaptation
                  </Card.Title>
                  <Card.Text className="textJustify">
                    The cloud landscape is constantly evolving, and your
                    business must evolve with it. We offer ongoing support and
                    improvement services to ensure that your cloud solutions
                    remain aligned with the latest trends and technologies. From
                    system upgrades to security enhancements, we help your cloud
                    infrastructure adapt to future needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <div
            style={{
              background: "black",
              borderRadius: "8px",
              color: "whitesmoke",
            }}
          >
            <h3 className="ps-3 pt-3">
              A Cloud-First Approach for Modern Businesses
            </h3>
            <p className="ps-3 pt-3">
              Orbio Solutions takes a proactive approach to cloud adoption. Our
              cloud services and solutions encompass all aspects of cloud
              infrastructure, from migration and security to optimization and
              user training. With our cloud consultant services, your business
              will gain a competitive edge in today's digital world.
            </p>
            <p className="ps-3">
              Our comprehensive suite of cloud managed services includes:
            </p>
            <Row className="pt-3 pb-0 pb-md-4 d-flex justify-content-center">
              <Col md={3} className="mb-2 mb-md-0">
                <Card className={styles.cloudCard}>
                  <Card.Body className={styles.cloudCardBody}>
                    <FaLightbulb style={{ fontSize: "40px" }} />
                    <Card.Title
                      style={{ fontSize: "13px" }}
                      className="text-center"
                    >
                      Cloud Strategy Consulting
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mb-2 mb-md-0">
                <Card className={styles.cloudCard}>
                  <Card.Body className={styles.cloudCardBody}>
                    <MdDeveloperMode style={{ fontSize: "40px" }} />
                    <Card.Title
                      style={{ fontSize: "13px" }}
                      className="text-center"
                    >
                      Cloud Application Development
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mb-2 mb-md-0">
                <Card className={styles.cloudCard}>
                  <Card.Body className={styles.cloudCardBody}>
                    <GiMove style={{ fontSize: "40px" }} />
                    <Card.Title
                      style={{ fontSize: "13px" }}
                      className="text-center"
                    >
                      Cloud Migration Services
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="pt-0 pt-md-3 pb-4 d-flex justify-content-center">
              <Col md={3} className="mb-2 mb-md-0">
                <Card className={styles.cloudCard}>
                  <Card.Body className={styles.cloudCardBody}>
                    <IoMdSettings style={{ fontSize: "40px" }} />
                    <Card.Title
                      style={{ fontSize: "13px" }}
                      className="text-center"
                    >
                      Cloud Infrastructure Management
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mb-2 mb-md-0">
                <Card className={styles.cloudCard}>
                  <Card.Body className={styles.cloudCardBody}>
                    <FaShieldAlt style={{ fontSize: "40px" }} />
                    <Card.Title
                      style={{ fontSize: "13px" }}
                      className="text-center"
                    >
                      Cloud Security Services
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className={styles.cloudCard}>
                  <Card.Body className={styles.cloudCardBody}>
                    <AiOutlineDollar style={{ fontSize: "40px" }} />
                    <Card.Title
                      style={{ fontSize: "13px" }}
                      className="text-center"
                    >
                      Cloud Cost Optimization
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <p className="ps-3 pb-3">
              At Orbio Solutions, we believe that the cloud is not just about
              technology it's about transforming your business to be more agile,
              efficient, and secure.
            </p>
          </div>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-black">
            Partner with Orbio Solutions for Cloud Success{" "}
          </h3>
          <p>
            Whether you're just starting your cloud journey or looking to
            optimize an existing cloud environment, Orbio Solutions is here to
            help. With our expertise in{" "}
            <Link to="/">cloud security services</Link> and comprehensive{" "}
            <span className="fw-bold">cloud managed services</span>, we empower
            businesses to harness the full potential of the cloud. Let us help
            you secure a future-proof cloud strategy that drives innovation,
            reduces costs, and ensures business continuity.
          </p>
        </Container>
      </div>

      <ContactFormService
        caseStudyLength={1}
        title={
          "We’re here to help you navigate the complexities of cloud computing. Reach out to our team today to learn more about our cloud services and solutions and how we can tailor them to your specific needs."
        }
      />
    </>
  );
};

export default CloudManagedServices;
